<template>
   <div class="grid">
      <Toast />
      <ConfirmDialog></ConfirmDialog>
      <div class="col-12">
         <div class="card">
            <Toolbar class="mb-4">
               <template #start>
                  <Button
                     label="Add"
                     icon="pi pi-plus"
                     class="p-button-success mr-2"
                     @click="addType"
                  />
               </template>
            </Toolbar>
            <DataTable
               ref="dt"
               :value="types"
               dataKey="id"
               :paginator="true"
               :rows="10"
               :filters="filters"
               :loading="loading"
               responsiveLayout="scroll"
            >
               <template #header>
                  <div
                     class="
                        table-header
                        p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                     "
                  >
                     <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                        Manage Doctor Types
                     </h5>
                     <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText
                           v-model="filters['global'].value"
                           placeholder="Search..."
                        />
                     </span>
                  </div>
               </template>

               <Column field="id" header="ID" style="min-width: 12rem"></Column>
               <Column
                  field="name"
                  header="Name"
                  style="min-width: 16rem"
               ></Column>
               <Column
                  field="comission_type"
                  header="Comission Type"
                  style="min-width: 10rem"
               ></Column>
               <Column
                  field="comission_value"
                  header="Comission value"
                  style="min-width: 10rem"
               ></Column>

               <Column :exportable="false" style="min-width: 8rem">
                  <template #body="{ data }">
                     <Button
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-success p-mr-2"
                        @click="editType(data)"
                     />
                     <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-warning"
                        @click="deleteType(data.id)"
                     />
                  </template>
               </Column>
            </DataTable>
            <Dialog
               v-model:visible="typeDialog"
               :style="{ width: '450px' }"
               header="Location Details"
               :modal="true"
               class="p-fluid"
            >
               <div class="field">
                  <label for="parent" class="mb-3">Parent</label>
                  <Dropdown
                     id="comission_type"
                     v-model="type.comission_type"
                     :options="comissionTypes"
                     optionLabel="name"
                     optionValue="code"
                     placeholder="--- Select a Comission ---"
                  >
                  </Dropdown>
               </div>

               <v-errors
                  :serverErrors="errorFor('comission_type')"
                  :vuelidateErrors="{
                     errors: v$.type.comission_type.$errors,
                     value: 'Comission Type',
                  }"
               ></v-errors>

               <div class="field">
                  <label for="name">Amount</label>
                  <InputText
                     id="comission_value"
                     v-model="type.comission_value"
                     required="true"
                     autofocus
                  />
               </div>

               <v-errors
                  :serverErrors="errorFor('comission_value')"
                  :vuelidateErrors="{
                     errors: v$.type.comission_value.$errors,
                     value: 'Comission Value',
                  }"
               ></v-errors>

               <div class="field">
                  <label for="name">Name</label>
                  <InputText
                     id="name"
                     v-model="type.name"
                     required="true"
                     autofocus
                  />
               </div>

               <v-errors
                  :serverErrors="errorFor('name')"
                  :vuelidateErrors="{
                     errors: v$.type.name.$errors,
                     value: 'Name',
                  }"
               ></v-errors>

               <template #footer>
                  <Button
                     label="Cancel"
                     icon="pi pi-times"
                     class="p-button-text"
                     @click="hideDialog"
                  />
                  <Button
                     label="Save"
                     icon="pi pi-check"
                     class="p-button-text"
                     @click="saveType(type)"
                  />
               </template>
            </Dialog>
         </div>
      </div>
   </div>
</template>

<script>
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
   name: "Specialist",

   setup() {
      return { v$: useVuelidate() };
   },
   mixins: [validationErrors],

   data() {
      return {
         types: null,
         type: {},
         comissionTypes: [
            { name: "FIX", code: "FIX" },
            { name: "PERCENT", code: "PERCENT" },
         ],
         loading: true,
         filters: {},
         typeDialog: false,
      };
   },
   validations() {
      return {
         type: {
            comission_value: { required },
            comission_type: { required },
            name: { required },
         },
      };
   },
   methods: {
      ...mapActions([
         "doctortype/fetchAllTypes",
         "doctortype/store",
         "doctortype/delete",
         "doctortype/update",
      ]),

      getAllTypes() {
         this["doctortype/fetchAllTypes"]()
            .then((response) => {
               this.types = response;
               this.loading = false;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      initFilters() {
         this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
         };
      },
      editType(data) {
         this.typeDialog = true;
         this.type = { ...data };
      },
      hideDialog() {
         this.typeDialog = false;
      },
      addType() {
         this.type = {};
         this.typeDialog = true;
      },
      async saveType(data) {
         const isFormCorrect = await this.v$.$validate();
         if (!isFormCorrect) return;
         if (data.id) {
            this["doctortype/update"](data)
               .then(() => {
                  this.loading = true;
                  this.typeDialog = false;
                  this.getAllTypes();

                  this.$toast.add({
                     severity: "success",
                     summary: "Confirmed",
                     detail: "Successfully updated.",
                     life: 3000,
                  });
               })
               .catch((error) => {
                  console.log(error);
               });
         } else {
            this["doctortype/store"](data)
               .then(() => {
                  this.loading = true;
                  this.typeDialog = false;
                  this.getAllTypes();

                  this.$toast.add({
                     severity: "success",
                     summary: "Confirmed",
                     detail: "Successfully created.",
                     life: 3000,
                  });
               })
               .catch((error) => {
                  console.log(error);
               });
         }
      },
      deleteType(id) {
         this.$confirm.require({
            message: "Are you sure you want to proceed?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "Successfully deleted.",
                  life: 3000,
               });
               this["doctortype/delete"](id)
                  .then(() => {
                     this.loading = true;
                     this.getAllTypes();
                     console.log(id);
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "You have rejected",
                  life: 3000,
               });
            },
         });
      },
   },

   created() {
      this.getAllTypes();
      this.initFilters();
   },
};
</script>

<style lang="scss" scoped>
.table-header {
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media screen and (max-width: 960px) {
      align-items: start;
   }
}
</style>
